class QrScannerIdChecks {
  constructor(options = {}) {
    this.container = $('#scan-idcheck-container')
    this.baseUrl = options['baseUrl'] || this.container.attr('data-url')
    this.editUrl = options['editUrl'] || this.container.attr('data-url-edit')
    this.authToken = options['authToken'] || this.container.attr('data-token')
    this.checkIdentity = options['checkIdentity'] || this.container.attr('data-precheck') || false
    this.scanHistoric = []
    this.element = document.getElementById('preview')
    this.scanner = new Instascan.Scanner({
      video: this.element,
      backgroundScan: false,
      mirror: false
    })
    this.scanner.addListener('scan', content => this.onScannedQrCode(content))
    Instascan.Camera.getCameras().then(cameras => {
      if (cameras.length > 0) this.addCameras(cameras)
      else throw new Error('No camera found')
    }).catch(e => {
      console.error(e)
      this.container.html(`<div class="scan-errors">${e.message}</div>`)
    })
  }
  onScannedQrCode (token) {
    const url = this.baseUrl + token + '/redirect_to_show_with_token/?token=' + this.authToken
    window.location.href = url
  }
  startCamera (camera) {

    // if (camera['name'] == 'Back') {}
    // else this.scanner.mirror = true
    this.scanner.start(camera)
  }

  normarlizeCameraName(name, index) {
    if (!name) return `Cam${index}`
    const words = name.split(' ')
    if (_.intersection([
      'back', 'rear', 'arrière'
    ], words).length > 0) name = 'Back'
    else if (_.intersection([
      'front', 'face', 'avant', 'facetime'
    ], words).length > 0) name = 'Front'
    return name
  }
  addCameras(cameras) {
    cameras.forEach((camera, index) => {
      camera.name = this.normarlizeCameraName(camera.name, index)
      const camLi = $(`<li class="nav-item" id="${camera.id}"><a class="nav-link link-unstyled" href="#">${camera.name}</a></li>`).on('click', e => {
        e.preventDefault()
        this.startCamera(camera)
        camLi.addClass('font-weight-bold').siblings().removeClass('font-weight-bold')
      })
      $('ul#camera-list').append(camLi)
      if (index === 0) camLi.trigger('click')
    })
  }
}

document.addEventListener('turbolinks:load', function () {
  if (!document.getElementById('scan-idcheck-container')) return
  const scanner = new QrScannerIdChecks()
  $('#scan-tab').on('hidden.bs.tab', () => {
    console.log('SCANNER STOP')
    scanner.scanner.stop()
  }).on('shown.bs.tab', () => {
    console.log('SCANNER START')
    scanner.scanner.start()
  })
})