export default {
  init,
  localBtnClick
}

function init () {
  $(document).ready(initSearch)
  $(document).on('load', initSearch)
  $(document).on('cocoon:after-insert', initSearch)
  $(document).on('cocoon:after-insert', initSearch)

  $(document).ready(initLocalBtn)
  $(document).on('load', initLocalBtn)
  $(document).on('cocoon:after-insert', initLocalBtn)
  $(document).on('cocoon:after-insert', initLocalBtn)
}

function initLocalBtn() {
  [...document.querySelectorAll('.localBtn')].forEach(button => {
    button.href = 'javascript:void(0);'
    button.onclick = (e) => {
      e.preventDefault()
      localBtnClick(e.target)
    }
  });
}

function initSearch () {
  [...document.querySelectorAll('.search')].forEach(search => {
    let searchRows = search.querySelector('.searchRows');

    [...searchRows.querySelectorAll("select[name$='[field]']")].forEach(input => {
      input.onchange = () => { fieldSelectChanged(input) }
    });
    [...search.querySelectorAll('.searchAddRow')].forEach(button => {
      button.href = 'javascript:void(0);'
      button.onclick = () => { addRow(search) }
    });
    [...search.querySelectorAll('.removeSearchRow')].forEach(button => {
      button.href = 'javascript:void(0);'
      button.onclick = removeRow
    });
  })
}

function generateSelect (options) {
  let HTMLoptions = []
  for (let [value, name] of Object.entries(options)) {
    HTMLoptions.push(`<option value='${value}'>${name}</option>`)
  }
  return [`<select>`, '<option selected></option>', ...HTMLoptions, '</select>'].join('')
}

function removeRow (button) {
  let searchRow = $(button.target).parents('.searchRow')[0]
  searchRow.parentNode.removeChild(searchRow)
}

function addRow (search) {
  let searchRowTemplate = search.querySelector('.searchRowTemplate')
  let searchRows = search.querySelector('.searchRows')

  let newSearchRow = document.createElement('div');
  newSearchRow.className = 'searchRow';
  let id = Math.floor(Math.random() * 1000000)
  newSearchRow.innerHTML = searchRowTemplate.innerHTML;
  [...newSearchRow.querySelectorAll('input'), ...newSearchRow.querySelectorAll('select')].forEach(e => {
    e.name = e.name.replace('{index}', id)
    e.id = e.name.replace('__index__', `_${id}_`)
  });
  searchRows.appendChild(newSearchRow)
  initSearch(search)
}

function replaceInputWithIntInput (input) {
  let newElem = document.createElement("input")
  let parentNode = input.parentNode

  newElem.name = input.name
  newElem.id = input.id
  newElem.type = 'number'
  newElem.classList = input.classList

  input.remove()
  parentNode.appendChild(newElem)
}

function replaceInputWithSelect (input, options) {
  let newElem = document.createElement("select")
  let parentNode = input.parentNode

  newElem.name = input.name
  newElem.id = input.id
  newElem.classList = input.classList

  let HTMLoptions = []
  for (let [value, name] of Object.entries(options)) {
    HTMLoptions.push(`<option value='${value}'>${name}</option>`)
  }
  newElem.innerHTML = ['<option selected></option>', ...HTMLoptions].join('')
  input.remove()
  parentNode.appendChild(newElem)
}

function fieldSelectChanged (fieldSelect) {
  let searchRow = $(fieldSelect).parents('.searchRow')[0]
  let search = $(searchRow).parents('.search')[0]
  let operatorSelect = searchRow.querySelector('select.operator')
  let inputValue = searchRow.querySelector('.valueInput')

  let selected = fieldSelect.selectedOptions[0]

  let data = JSON.parse(search.getAttribute('data'))[selected.value]
  console.log(data)

  switch (data ? data.type : null) {
    case 'enum':
      operatorSelect.innerHTML = generateSelect(data.operators)
      inputValue.classList.remove('d-none')
      operatorSelect.classList.remove('d-none')
      replaceInputWithSelect(inputValue, data.options)
      break;
    case 'int':
      operatorSelect.innerHTML = generateSelect(data.operators)
      inputValue.classList.remove('d-none')
      operatorSelect.classList.remove('d-none')
      replaceInputWithIntInput(inputValue)
      break;
    case 'bool':
      operatorSelect.innerHTML = generateSelect(data.operators)
      operatorSelect.classList.remove('d-none')
      inputValue.classList.add('d-none')
      break;
    default:
      operatorSelect.classList.add('d-none')
      inputValue.classList.add('d-none')
      break;
  }
}

function localBtnClick (button) {
  let currentLocale = button.attributes.locale.value
  let target = button.attributes.base.value;
  [...$(`.localAttrs[base='${target}']`)].forEach(e => {
    if (currentLocale === e.attributes.locale.value) {
      e.classList.remove('d-none')
    } else {
      e.classList.add('d-none')
    }
  });
  [...$(`.localBtn[base='${target}']`)].forEach(e => {
    if (currentLocale === e.attributes.locale.value) {
      e.classList.add('btn-primary')
      e.classList.remove('btn-secondary')
    } else {
      e.classList.remove('btn-primary')
      e.classList.add('btn-secondary')
    }
  });
  let textArea = document.querySelector("[data-markdownable='true']")
  if (textArea) { textArea.dispatchEvent(new Event('keyup')) }
}

$(document).on("turbolinks:load", function () {

  let mailableType = document.getElementById("mailing_group_mailable_type");
  let expectedStep = document.getElementById("mailing_group_expected_step_id");

  if (mailableType !== null && expectedStep !== null) {

    mailableType.addEventListener('change', (event) => {
      if (event.currentTarget.selectedOptions[0].value === "CampusStep") {
        expectedStep.removeAttribute("disabled");
        expectedStep.classList.remove("disabled");
      } else {
        expectedStep.setAttribute("disabled", "disabled");
        expectedStep.classList.add("disabled")
      }
    })
  }
});
