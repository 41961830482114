export default {
    initEmailFormButton
}

function initEmailFormButton() {
    const btn = document.createElement("button");
    btn.setAttribute("id", "mass_email_btn");
    btn.innerHTML = "email";
    btn.className = "btn-xs btn btn-outline-primary mr-2"
    const parentNode = $('.pull-right.btn-xs.btn.btn-outline-primary');
    const exists = document.getElementById('mass_email_btn')
    if (!exists)
        $(btn).insertBefore(parentNode)
    btn.addEventListener('click', () => {
        showEmailForm();
    })
}

function listenToClearLocalStorage() {
    const btn = document.getElementById('clear-email-users-btn');
    btn.addEventListener('click', () => {
        clearLocalStorageAndResetForm();
    })
}

function clearLocalStorageAndResetForm() {
    localStorage.removeItem('email');
    const subject = document.getElementById('email-search-users-subject');
    const content = document.getElementById('email-search-users-content');
    subject.value = "";
    content.value = "";
    const markdownPreview = document.getElementById('markdown-preview');
    markdownPreview.innerHTML = content.value;
    initLocalStorage();
}

function getEmailFromLocalStorage() {
    return localStorage.getItem('email');
}

function initLocalStorage() {
    let email = getEmailFromLocalStorage();
    if (email === null) {
        email = {
            subject: "",
            content: ""
        };
        localStorage.setItem('email', JSON.stringify(email));
    } else {
        const json = JSON.parse(email);
        const subject = document.getElementById('email-search-users-subject');
        const content = document.getElementById('email-search-users-content');
        subject.value = json.subject;
        content.value = json.content;
        const markdownPreview = document.getElementById('markdown-preview')
        markdownPreview.innerHTML = content.value;
        initMarked()
    }
}

function listenToFormInputs() {
    const subject = document.getElementById('email-search-users-subject');
    const content = document.getElementById('email-search-users-content');
    subject.addEventListener('blur', saveSubject);
    content.addEventListener('blur', saveContent);
}

function listenToFormSubmit() {
    const btn = document.getElementById('email-users-btn');
    btn.addEventListener('confirm:complete', submitMassEmailForm);
}

function saveContent(e) {
    saveToLocalStorage("content", e.target.value);
}

function saveSubject(e) {
    saveToLocalStorage("subject", e.target.value);
}

function saveToLocalStorage(type, value) {
    const email = getEmailFromLocalStorage();
    let json = JSON.parse(email);
    if (type === "subject") {
        json.subject = value;
        localStorage.setItem('email', JSON.stringify(json));
    } else if (type === "content") {
        json.content = value;
        localStorage.setItem('email', JSON.stringify(json));
    }
}

function showEmailForm() {
    const subject_input = document.getElementById('email-search-users-subject');
    const content_input = document.getElementById('email-search-users-content');
    if (subject_input.classList.contains('is-invalid'))
        subject_input.classList.remove('is-invalid')    
    if (content_input.classList.contains('is-invalid'))
        content_input.classList.remove('is-invalid')    
    const container = $('#email-search-users')
    if (container.hasClass('d-none')) {
        container.removeClass('d-none');
        initLocalStorage();
        listenToFormInputs();
        listenToClearLocalStorage();
        listenToFormSubmit();
    } else {
        container.addClass('d-none');
    }
}

function getWindowUrl() {
    let url = window.location.href;
    if (url.includes('/users')) {
        const res = url.split("/users", 1);
        return res + '/users/submit_mass_email';
    }
    return false
}

function serializeFormParamsToJson(selector) {
    let objs = $(selector).serializeArray();
    let ret = {};
    for (let obj of objs) {
        let levels = obj.name.replace(/]/g, "").split('[');
        let cur = ret;
        for (let i = 0; i < levels.length - 1; i++) {
            let level = levels[i];
            if (!(level in cur)) {
                cur[level] = {};
            }
            cur = cur[level];
        }
        cur[levels[levels.length - 1]] = obj.value
    }
    return ret;
}

async function submitMassEmailForm() {
    const email = getEmailFromLocalStorage();
    let { subject, content } = JSON.parse(email);
    if (subject === "" && content === "") {
        const subject_input = document.getElementById('email-search-users-subject');
        const content_input = document.getElementById('email-search-users-content');
        subject_input.classList.add('is-invalid')
        content_input.classList.add('is-invalid')
        subject_input.addEventListener('focus', (e) => e.target.classList.remove('is-invalid'))
        content_input.addEventListener('focus', (e) => e.target.classList.remove('is-invalid'))
        return
    }
    if (subject === "") {
        const subject_input = document.getElementById('email-search-users-subject');
        subject_input.classList.add('is-invalid')
        subject_input.addEventListener('focus', (e) => e.target.classList.remove('is-invalid'))
        return
    }
    if (content === "") {
        const content_input = document.getElementById('email-search-users-content');
        content_input.classList.add('is-invalid')
        content_input.addEventListener('focus', (e) => e.target.classList.remove('is-invalid'))
        return
    }
    const url = getWindowUrl();
    if (url) {
        this.disabled = true;
        this.classList.add('disabled')
        let payload = serializeFormParamsToJson('#user_search');
        payload['subject'] = subject;
        payload['content'] = content;
        const res = await fetch(createRequest(url, 'post', payload));
        if (res.status === 200) {
            clearLocalStorageAndResetForm();
            this.disabled = false;
            this.classList.remove('disabled')
            showEmailForm();
            showSuccessMessage();
        }
        else {
            this.disabled = false;
            this.classList.remove('disabled')
            return alert('Oops! Something wrong happened please refresh your page');
        }
    } else  {
        return alert('Oops! Something wrong happened please refresh your page');
    }
}

function createRequest(url, method = 'get', data) {
    const request = new window.Request(url, {
        headers: getHeaders(),
        method: method,
        credentials: 'same-origin',
        dataType: 'json',
        body: JSON.stringify(data)
    });
    return request;
}

function getHeaders() {
    let headers = new window.Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    });
    const csrfToken = document.head.querySelector("[name='csrf-token']").getAttribute('content');
    if (csrfToken)
        headers.append('X-CSRF-Token', csrfToken);
    return headers;
}

function showSuccessMessage() {
    const successMessage = $('#success-message')
    if (successMessage.hasClass('d-none')) {
        successMessage.fadeIn()
        successMessage.removeClass('d-none');
        setTimeout(() => {
            successMessage.fadeOut('slow', () => {
                successMessage.addClass('d-none')
            });
        }, 3000)
    }
}
