import { bindRemoveFileFromInput } from './file_input.js'
import './campus_steps'
import './datepicker'
import './home'
import './image_preview_fix'
import './introductions'
import './issue'
import './masks'
import './pipeline'
import './piscine'
import './ransack'
import './id_checks'
import { replaceWith } from './replace_with'
import './scan_list'
import './scan'
import './scan_id_checks'
import './sortable'
import './tours'
import './video'
import './user_confirmation_steps'
import './answers_contingent'
import './select2'
import SimpleFormSelect2 from './simple_form_select2'
import MassEmail from 'email_users'
import MailingIframe from 'mailing_iframe'
import MailingEdit from 'mailing_edit'
import MailingGroupEdit from 'mailing_group_edit'
import MailingGroupShow from 'mailing_group_show'
import CampusEdit from 'campus_edit'
import initCalendarDropdown from './calendarize_event'


function init() {
  if (document.getElementById('add-cal-button')) {
	console.log('init cal')
    initCalendarDropdown(document)
  }
  bindRemoveFileFromInput();
  if (document.getElementById("simple_mde")) {
    new SimpleMDE({
      element: document.getElementById("simple_mde"),
      spellChecker: false
    });
  }

  MailingGroupEdit.init();
  MailingGroupShow.init();
  window.MailingIframe = MailingIframe
  window.MailingEdit = MailingEdit
  window.MailingGroupEdit = MailingGroupEdit
  window.CampusEdit = CampusEdit
  window.MassEmail = MassEmail
  replaceWith();
  $('.chosen-select').chosen()
  $('.custom-answerable').on('change', function(evt, params) {
    const custom_input = $('.custom-answer-input')
    const customizable = $(this).data('customizable')
    if (params.selected == customizable)
      custom_input.toggleClass('d-none')
    else if (params.deselected == customizable) {
      custom_input.find('textarea').val('')
      custom_input.toggleClass('d-none')
    }
  });
  // bootstrap js
  $('[data-toggle=tooltip]').tooltip();
  $('[data-toggle=tab]').tab();
  $('a[data-toggle]').click(function(e) {
    e.preventDefault();
  });
  // markdown each marked elem
  $('.marked').each(function() {
    this.innerHTML = marked(this.innerHTML);
  });
  // puts document name on file input
  $('.custom-file input').on('change', function(){
    $(this).next('label').addClass("selected").html($(this).val());
    if ($(this).next('label').hasClass('removeable-file')) {
      var toRemove = $(this).parent().next('.input-group-append').find('[data-add-input]').data("add-input");
      $(`[name="${toRemove}"]`).remove();
    }
  })

  // auto-resize textareas
  $("textarea").each(function(input) {
    $(this).on('keyup', function() {
      var scroll_height = this.scrollHeight;
      if (scroll_height > 60) {
        $(this).css('height', scroll_height + 'px');
      }
    });
  });
  if ($('input[name=authenticity_token]').length > 0) {
    $('input[name=authenticity_token]').val($('meta[name=csrf-token]').attr('content'))
  }
  SimpleFormSelect2.init();
}

window.onhashchange = window.onload = function(evt) {
  // overwrite current url on some actions so users can get the correct page when they reload
  var currentAction = $('body').data('action');
  var actionMapping = new Object();
  actionMapping['update'] = 'edit'
  actionMapping['create'] = 'new'
  actionMapping['submit_answers'] = 'answers'
  actionMapping['submit_mass_switch_step'] = 'mass_switch_step'
  var newAction = actionMapping[currentAction]
  if (newAction) {
    var newUrl = Turbolinks.controller.location.getPath().split('/').filter(function(e) {
      if (e !== currentAction) {
        return e
      }
    }).concat([newAction]).join('/')
    Turbolinks
      .controller
      .pushHistoryWithLocationAndRestorationIdentifier('/' + newUrl, Turbolinks.uuid())
  }
};


document.addEventListener("turbolinks:load", function() {
  init();
  if (window.initCookie) {
    window.initCookie();
  }
  if ($('#page-content > div').last().hasClass('jumbotron')) {
    $('#page-content').addClass('bg-white');
    $('#page-wrapper').addClass('bg-ultra-light');
  }
});

document.addEventListener("turbolinks:before-cache", function () {
    $('[data-toggle="tooltip"]').tooltip('hide');
});

$(document).on("cocoon:after-insert", function(evt, newItem) {
  const newId = new Date().getTime();
  _.each(newItem.find("a.nav-link"), function(navLink) {
    const tab = newItem.find($(navLink).attr('href')).first();
    tab.attr('id', tab.attr('id') + newId);
    $(navLink).attr('href', '#' + tab.attr('id'));
  });
  init();
})
