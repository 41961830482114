 class QrScanner {
  constructor(options = {}) {
    let self = this
    self.baseUrl = options['baseUrl'] || $('#scan-container').attr('data-url');
    self.editUrl = options['editUrl'] || $('#scan-container').attr('data-url-edit');
    self.authToken = options['authToken'] || $('#scan-container').attr('data-token');
    self.checkIdentity = false || options['checkIdentity'] || $('#scan-container').attr('data-precheck');
    self.scanHistoric = []
    this.container = document.getElementById('scan-container')
    this.element = document.getElementById('preview')

    this.scanner = new Instascan.Scanner({
      video: this.element,
      backgroundScan: false,
      mirror: false
    });
    this.scanner.addListener('scan', content => this.onScannedQrCode(content));
    Instascan.Camera.getCameras().then(cameras => {
      if (cameras.length > 0) {
        this.addCameras(cameras);
      } else {
        console.error('No cameras found.');
      }
    }).catch(e => {
      if (this.container) this.container.innerHTML = `<div class="scan-errors">${e.message}</div>`
    });
  }

  onScannedQrCode(token) {
    this.getUserData(token);
  }

  getUserData(token) {
    let url = this.baseUrl + token + '/user_data.json?admin_token=' + this.authToken

    $.get({
      url: url,
      success: response => {
        if (response.hasOwnProperty('error')) {
          this.displayResponse('danger', response.error);
        }
        else {
          let hasWarnings = response['account_warnings'].length
          if ( hasWarnings || this.checkIdentity ) {
            this.askForConfirmation({
              data: response,
              success: () => this.validateResource(token)
            });
          }
          else this.validateResource(token)
        }
      },
      error: response => {
        this.displayResponse('danger', response.responseText);
      }
    });
  }

  askForConfirmation(opts = {}) {
    let hasWarnings = opts['data']['account_warnings'].length
    let validateBtn = $(`
      <button type="button" id="validate-scan" class="btn btn-sm btn-primary mt-1">
        <span>validate</span>
      </button>
    `);
    let editBtn = $(`
      <button type="button" id="edit-user-scan" class="btn btn-sm btn-warning mr-1 mt-1">
        <span>edit</span>
      </button>
    `);
    let validateModale = $(`
      <div class="scan-response alert-dismissible fade show alert alert-${hasWarnings ? 'danger' : 'primary'} my-0">
          <b>First Name:</b> ${opts['data']['first_name']}
          </br>
          <b>Last Name:</b> ${opts['data']['last_name']}
          </br>
          <b>Birth Date:</b> ${opts['data']['birth_date']}
          </br>
          <b>Email:</b> ${opts['data']['email']}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          </br>
       </div>
    `);


    let warnings = _.uniq(opts['data']['account_warnings'].filter(function(warning) {
      return warning.state === 'invalid';
    }).map(warning => warning.kind)).map(function(kind) {
       return `At least one <b>invalid warning with kind ${kind}</b>!`;
    }).join('</br>') + '</br>';

    $('.scan-response').remove();
    $('body').prepend(validateModale);
    console.log("TUTU");
    validateModale.append(warnings);
    validateModale.append(editBtn);
    validateModale.append(validateBtn);
    editBtn.on('click', function() {
      window.open(self.editUrl + opts['data']['slug'] + '/edit');
    });
    validateBtn.on('click', function() {
      opts['success'].call();
    });
  }

  validateResource(token) {
    let url = this.baseUrl + token + '/validate.json?admin_token=' + this.authToken
    $.post({
      url: url,
      success: response => {
        if (response.hasOwnProperty('error')) {
          this.displayResponse('danger', response.error);
        }
        if (response.hasOwnProperty('success')) {
          this.displayResponse('success', response.success);
        }
      },
      error: response => {
        this.displayResponse('danger', response.statusText);
      }
    });
  }

  displayResponse(cssClass, message) {
    $('.scan-response').remove();
    $('body').prepend(
      `<div class='scan-response alert-dismissible fade show alert alert-${cssClass} text-${cssClass} my-0'>
          ${message}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
       </div>`
    );
  }
  startCamera(camera) {
    if (camera['name'] == 'Back') {

    }
    else {
      // maybe rajouter option pour un mode mirror
    }
    this.scanner.start(camera);
  }

  normarlizeCameraName(camera) {
    name = (camera['name'] || 'undefined').toLowerCase();
    if (_.intersection(['back', 'rear', 'arrière'], name.split(' ')).length > 0) {
      camera['name'] = 'Back';
    }
    else if (_.intersection(['front', 'face', 'avant'], name.split(' ')).length > 0) {
      camera['name'] = 'Front';
    }
    return camera;
  }

  addCameras(cameras) {
    // adds available cameras to the list and handles the select of a new camera
    _.each(cameras, (camera, index) => {
      camera = this.normarlizeCameraName(camera);
      let camLi = $(`<li class="nav-item" id="${camera['id']}"><a class="nav-link link-unstyled" href="#">${camera['name']}</a></li>`);
      $('ul#camera-list').append(camLi);
      camLi.on('click', e => {
        e.preventDefault();
        this.startCamera(camera);
        camLi.parent().children().addClass('text-muted');
        camLi.removeClass('text-muted');
        camLi.addClass('disabled')
      })
      if (index == 0) {
        camLi.trigger('click')
      }
    });
  }
}

document.addEventListener("turbolinks:load", function() {
  if ($('#scan-container').length >= 1) {
    let scanner = new QrScanner();
    $('#scan-tab').on('hidden.bs.tab', function() {
      scanner.scanner.stop();
    });
    $('#scan-tab').on('shown.bs.tab', function() {
      scanner.scanner.start();
    });
  }
});
