export default {
  allowOtherGenderChange,
  disableSignupChange
}

function allowOtherGenderChange (input) {
  let reasonField = input.form.querySelector('.no_other_gender_repartition_reason')
  if (!reasonField) { return }
  if (input.value != 'true') {
    reasonField.classList.remove('d-none')
  } else {
    reasonField.classList.add('d-none')
  }
}

function disableSignupChange (input) {
  let reasonField = input.form.querySelector('.disable_signup_reason')
  if (!reasonField) { return }
  if (input.value != 'true') {
    reasonField.classList.add('d-none')
  } else {
    reasonField.classList.remove('d-none')
  }
}
