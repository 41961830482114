class Piscine {
  constructor(options = {}) {
    self = this;
    self.winnersList = [];
    self.baseUrl = options['baseUrl'] || $('#upload-winners-container').attr('data-url');
    self.submitBtn = $("form#uploadWinners input[type=submit]");
    $("form#uploadWinners").on('click', "input[type=submit]" , function(e){;
      let winnersList = $("form#uploadWinners textarea[name='piscine[content]']").val().split("\n").filter(Boolean);
      self.getPiscineData(winnersList);
    });
  }

  getPiscineData(winnersList) {
    let url = self.baseUrl + '.json?piscine[content]=' + winnersList;
    let data_confirm_val = self.submitBtn.data("confirm");
    let $data_confirm_elem = $("<p>" + data_confirm_val + "</p>");
    $.get({
      url: url,
      success: function(response) {
        $data_confirm_elem.find("b#count_admitted").text(response.count_winners);
        $data_confirm_elem.find("b#count_anonymized").text(response.count_losers);
        $data_confirm_elem.find("b#count_not_found").text(response.count_non_existing);
        if (response.count_non_existing == 0) {
          let data_confirm_html = $data_confirm_elem.html();
          $data_confirm_elem.html(data_confirm_html.substring(0, data_confirm_html.lastIndexOf("<br>")));
        }
        self.submitBtn.getConfirmModal().find('.modal-body p').html($data_confirm_elem.html());
        self.submitBtn.confirmModal();
      },
      error: function(response) {
        let error_txt = '<br/><br/><p class="text-danger">There was an error retrieving the informations of the piscine applicants !</p>';
        self.submitBtn.getConfirmModal().find('.modal-body p').html(data_confirm_val + error_txt);
        self.submitBtn.confirmModal();
      }
    });
  }
}

document.addEventListener("turbolinks:load", function() {
  if ($('#upload-winners-container').length >= 1) {
    let piscine = new Piscine();
  }
});
