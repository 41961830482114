
function BrowserCheck()
{
    var nav = navigator.appName;
    var ua = navigator.userAgent;
    var vers = navigator.userAgent;
    var browser = ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
    if(browser && (vers= ua.match(/version\/([\.\d]+)/i))!= null) {browser[2]=vers[1];}
    browser = browser? [browser[1], browser[2]]: [nav, navigator.appVersion,'-?'];
    return browser;
}

function getOS() {
  var OSName="Unknown OS";
  if (navigator.platform.indexOf("Win")!= -1) OSName="Windows";
  if (navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
  if (navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
  if (navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
  if (navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
  if (navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
  if (navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
  if (navigator.platform.indexOf("Mac")!=-1) OSName="MacOS";
  if (navigator.userAgent.indexOf("Mac OS X 10_12") != -1) OSName="MAC OS X Sierra";
  if (navigator.userAgent.indexOf("Mac OS X 10_11") != -1) OSName="MAC OS X El Capitan";
  if (navigator.userAgent.indexOf("Mac OS X 10_10") != -1) OSName="MAC OS X Yosemite";
  if (navigator.userAgent.indexOf("Mac OS X 10_13") != -1) OSName="MAC OS X High Sierra";
  if (navigator.userAgent.indexOf("Mac OS X 10_14") != -1) OSName="MAC OS X Mojave";
  if (navigator.platform.indexOf("X11")!= -1) OSName="UNIX";
  if (navigator.platform.indexOf("Linux")!= -1) OSName="Linux";
  if (navigator.platform.indexOf("iP")!= -1) OSName="IOS";
  if (navigator.platform.indexOf("Android")!= -1) OSName="Android";
  return OSName;
}

$(document).on("turbolinks:load", function () {
  if (document.getElementById("browser")) {
    document.getElementById("browser").value = "Using " + BrowserCheck() + " on " + getOS();
  }

  $("#resolve-and-comment").on("click", function() {
    $("#message_resolve").val(true)
  })

  $("#deny-and-comment").on("click", function() {
    $("#message_resolve").val(false)
  })

  if (document.getElementById("issue_category_id") !== null && document.getElementById("issue_campus_div") !== null) {
    let category = document.getElementById("issue_category_id");
    let campus = document.getElementById("issue_campus_div");
    let csrCategoryId = document.getElementById('issue_campus_category_id').dataset.categoryId;

    if (parseInt(category.selectedOptions[0].value) === parseInt(csrCategoryId)) {
      campus.removeAttribute("hidden");
    }

    document.getElementById("issue_category_id").addEventListener('change', (event) => {
      if (parseInt(event.currentTarget.selectedOptions[0].value) === parseInt(csrCategoryId)) {
        campus.removeAttribute("hidden");
      } else campus.setAttribute("hidden", "");
    })
  }
});

