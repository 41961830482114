document.addEventListener('turbolinks:load', function() {
  if ($('.ransack-form').length === 0) {
    return;
  }
  const selects = $('.ransack-form').data('selects');
  const selecte = $('.ransack-form').data('selecte');
  const selecti = $('.ransack-form').data('selecti');
  const selectb = $('.ransack-form').data('selectb');
  const selectd = $('.ransack-form').data('selectd');
  const attrtype = $('.ransack-form').data('types');
  const enums = $('rasack-form').data('enums');

  $('form').on('click', '.remove_fields', function(event) {
    $(this).closest('.field').remove();
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    const time = new Date().getTime();
    const regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });

  // les predicats qui n'ont pas besoin de valeur
  $('form').on('change', 'select#predic', function(event) {
    let type = attrtype[$('form select:first-of-type').val()];
    if (type === "datetime") type = "date";
    const cond = $(this).val();
    const valueInput = $(this).parent().children('.value-field');

    if ($.inArray(cond, ['present', 'blank', 'true', 'false', 'null', 'not_null']) >= 0) {
      valueInput.prop("type", "text")
      valueInput.val('1');
      return valueInput.hide();
    } else {
      valueInput.prop("type", type)
      return valueInput.show();
    }
  });
  $('form').on('change', 'select:first-of-type', function(event) {

    let key, selectInputVal, value;
    const getSelectOptions = (param, array, originValueInputId) =>
      Array.from(array).map((i) =>
        i[1] === param ?
          $(`#${originValueInputId}`).append(`<option selected='selected' value='${i[1]}'>${i[0]}</option>`)
        :
          $(`#${originValueInputId}`).append(`<option value='${i[1]}'>${i[0]}</option>`))
    ;

    const attributeName = $(this).val();
    const predicateSelect = $(this).next('select#predic'); // le selector du predicat (ex: inferieur a )
    const valueInput = $(this).parent().children('.value-field'); // la valeur du champ text (ou le select en cas d'enum)
    const selectInput = predicateSelect.next('select'); //Le selector du select en cas d'enum
    const predicateValue = predicateSelect.find('option:selected').val(); //la valeur selectionnee du select predicat
    const selectedEnumValue = predicateSelect.next('input').val(); //la valeur selectionnee du select enum
    const type = attrtype[attributeName]; //le type de l'attribut
    const originValueInputId = valueInput.attr('id'); //la valeur originale de l'ID du champ text (ou le select de l'enum)
    const originValueInputName = valueInput.attr('name'); //la valeur originale du nom du champ text (ou le select de l'enum)
    const enumselect = $(`select#${originValueInputId}`).val();
    const lastField = predicateSelect.next('input');
    predicateSelect.empty();

    if (type !== "enum") {
      predicateSelect.trigger('change');
      predicateSelect.next('select').replaceWith(`<input class='form-control value-field' type='text' name=${originValueInputName} id=${originValueInputId}>`);
    }
    if (lastField.attr('type') == 'date' && ((type !== 'date') || (type !== 'datetime'))) {
      lastField.attr('type', 'string');
    }

    if (typeof selectInput.val() === "undefined") {
      selectInputVal = valueInput.val();
    } else {
      selectInputVal = selectInput.val();
    }
    if (type === 'boolean') {
      lastField.val('1');
      valueInput.hide();
      return (() => {
        const result = [];
        for (let key in selectb) {
          const value = selectb[key];
          if (key !== predicateValue) {
            result.push(predicateSelect.append(`<option value='${key}'>${value}</option>`));
          } else {
            result.push(predicateSelect.append(`<option selected='selected' value='${key}'>${value}</option>`));
          }
        }
        return result;
      })();

    } else if ((type === 'date') || (type === 'datetime') || (type === 'integer')) {
      valueInput.show();
      for (key in selecti) {
        value = selecti[key];
        if (key !== predicateValue) {
          predicateSelect.append(`<option value='${key}'>${value}</option>`);
        } else {
          predicateSelect.append(`<option selected='selected' value='${key}'>${value}</option>`);
        }
      }
      if ((type === 'date') || (type === 'datetime')) {
        return predicateSelect.next('input').attr('type', 'date');
      } else {
        return predicateSelect.next('input').attr('type', 'number');
      }

    } else if ((type !== undefined) && (type[0] === 'enum')) {
      for (key in selecte) {
        value = selecte[key];
        if (key === predicateValue) {
          predicateSelect.append(`<option selected='selected' value='${key}'>${value}</option>`);
        } else {
          predicateSelect.append(`<option value='${key}'>${value}</option>`);
        }
      }

      valueInput.replaceWith(`<select class='form-control value-field' id='${originValueInputId}' name='${originValueInputName}'>`);
      selectInput.replaceWith(`<select class='form-control value-field' id='${originValueInputId}' name='${originValueInputName}'>`);

      if ((typeof originValueInputId === "undefined") && (typeof originValueInputName === "undefined")) {
        return;
      }
      return getSelectOptions(selectInputVal, type[1], originValueInputId);

    } else {
      valueInput.show();
      return (() => {
        const result1 = [];
        for (key in selects) {
          value = selects[key];
          if (key !== predicateValue) {
            result1.push(predicateSelect.append(`<option value='${key}'>${value}</option>`));
          } else {
            result1.push(predicateSelect.append(`<option selected='selected' value='${key}'>${value}</option>`));
          }
        }
        return result1;
      })();
    }
  });
  return $('select').trigger('change');
});

