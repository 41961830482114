export default {
  resizeIframe,
  buttonToTest,
  buttonToChangeLocale,
  selectChanged,
  buttonToShowFilter
}

function resizeIframe (iframe) {
  let iframeRect = iframe.contentWindow.document.scrollingElement.getBoundingClientRect()
  let iframeWidth = iframeRect.width

  if (iframeWidth < iframe.contentWindow.document.body.scrollWidth) {
    console.log('resize width')
    iframe.style['width'] = (iframe.contentWindow.document.body.scrollWidth + 40)+'px'
  }
  iframe.style['height'] = (iframe.contentWindow.document.body.scrollHeight + 110)+'px'
}

function buttonToTest (button) {
  let targets = [...$(button.attributes.target.value)]
  if (button.classList.contains('btn-primary')) {
    button.classList.remove('btn-primary')
    button.classList.add('btn-secondary')
    targets.forEach(e => {
        addTestWithModel(e, false)
    })
  } else {
    targets.forEach(e => {
      button.classList.add('btn-primary')
      button.classList.remove('btn-secondary')
      addTestWithModel(e, true)
    })
  }
}

function buttonToShowFilter (button, mailingId) {
  let targetId = button.attributes.target.value;
  [...$(targetId)].forEach(e => {
    changeMailing(e, mailingId)
    resizeIframe(e)
  });

  [...$(`.mbtn[target='${targetId}']`)].forEach(e => {
    e.classList.remove('btn-primary')
    e.classList.add('btn-outline-primary')
  })
  button.classList.add('btn-primary')
  button.classList.remove('btn-outline-primary')
}

function buttonToChangeLocale (button, locale) {
  let targetId = button.attributes.target.value;
  [...$(targetId)].forEach(e => {
    changeLocale(e, locale)
    resizeIframe(e)
  });

  [...$(`.lbtn[target='${targetId}']`)].forEach(e => {
    e.classList.remove('btn-primary')
    e.classList.add('btn-secondary')
  })
  button.classList.add('btn-primary')
  button.classList.remove('btn-secondary')
}

function changeLocale (iframe, locale) {
  let url = new URL(iframe.src)
  url.searchParams.set('locale', locale)
  iframe.src = url.origin + url.pathname + '?' + url.searchParams.toString()
}

function changeMailing (iframe, mailingId) {
  let url = new URL(iframe.src)
  let newPath = url.pathname.replace(/\/[0-9]+\/preview/, `/${mailingId}/preview`)
  iframe.src = url.origin + newPath + '?' + url.searchParams.toString()
}

function selectChanged (selector) {
  let selected = selector.selectedOptions[0].value
  let targetId = selector.attributes.target.value;
  [...$(targetId)].forEach(iframe => {
    let url = new URL(iframe.src)
    url.searchParams.set('test_with_mailable_id', selected)
    iframe.contentWindow.document.body.innerHTML = ""
    iframe.src = url.origin + url.pathname + '?' + url.searchParams.toString()
    resizeIframe(iframe)
  });
}

function addTestWithModel (iframe, state) {
  let url = new URL(iframe.src)
  url.searchParams.set('test_with_random', state)
  iframe.contentWindow.document.body.innerHTML = ""
  iframe.src = url.origin + url.pathname + '?' + url.searchParams.toString()
}
