document.write('<link rel="stylesheet" href="/vendor_assets/stylesheets/flatpickr.min.css">\n')
document.write('<script src="/vendor_assets/javascripts/flatpickr.min.js">\/</script>\n')
document.write('<!--[if lt IE 9]>\n')
document.write('  <script src = "/vendor_assets/javascripts/flatpickr-ie.css"></script>\n')
document.write('<![endif]-->')

$(document).on('turbolinks:load cocoon:after-insert', function () {
  $("input[type='date']").flatpickr({ dateFormat: "Y-m-d" , clickOpens: "true", allowInput: "true"});
  $("input[type='datetime'], input[type='datetime-local']").flatpickr({ dateFormat: "Y-m-d H:i", enableTime: true , clickOpens: "true", allowInput: "true"});
})
