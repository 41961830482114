function hide_campus_step_fields() {
  $('#forms .list-group-item').each(function(){
    if (['radio', 'select', 'multiselect'].includes($(this).find('#campus-steps-kind').val()))
      $(this).find('#possible-answers').show();
    else
     $(this).find('#possible-answers').hide();
  });
}

document.addEventListener("turbolinks:load", function() {
  hide_campus_step_fields();
});

$(document).on('change', '#campus-steps-kind', function() {
	hide_campus_step_fields();
});

$(document).on("cocoon:after-insert", function(evt, newItem) {
  hide_campus_step_fields();
  if ($('#forms .list-group-item:visible').length > 1)
    $('#forms .list-group-item').find('a.remove_fields').show();
});

$(document).on("cocoon:after-remove", function(evt) {
  if ($('#forms .list-group-item:visible').length == 1)
    $('#forms .list-group-item').find('a.remove_fields').hide();
});

document.addEventListener("turbolinks:load", function() {
  if ($('#forms .list-group-item:visible').length == 1)
    $('#forms .list-group-item').find('a.remove_fields').hide();
});
