$(document).on("turbolinks:load", function () {

    let contContent = $('#answers-contingents-content')

    contContent.on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
        defineSelectorEvent(insertedItem, $(insertedItem).find('select[data-answers-contingents-form]'))
    })

    contContent.find('.answers-contingents').each((index, parent) => {
        defineSelectorEvent(parent, $(parent).find('select[data-answers-contingents-form]'))
    })

    function defineSelectorEvent(parent, selector) {
        selector.on('change', () => { formSelected(parent, selector) })
        formSelected(parent, selector)
    }

    function formSelected(parent, selector) {
        let selected = selector.find('option:selected').val()
        let template = $(parent).find(`template#answers-contingents-form-${selected}-options`).html()
        let content = $(parent).find('#answers-contingents-data')
        content.html(template)
    }

})