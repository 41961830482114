export default {
  init
}

function updatePolymorphicFields() {
    $(this.closest('.simple_form')).find('#' + this.id.replace('_select2', '_id')).attr('value', $(this).select2('data')[0].id );
    $(this.closest('.simple_form')).find('#' + this.id.replace('_select2', '_type')).attr('value', $(this).select2('data')[0].entity_type );
}

function updateField() {
  $(this.closest('.simple_form')).find('#' + this.id.replace('_select2', '')).attr('value', $(this).select2('data')[0][this.dataset.selectedAttribute || 'id'] );
}

function getPolymorphicFieldsValues(element) {
  return ({
    id: $(element.closest('.simple_form')).find('#' + element[0].id.replace('_select2', '_id')).attr('value'),
    type: $(element.closest('.simple_form')).find('#' + element[0].id.replace('_select2', '_type')).attr('value') })
}

function getFieldValue(element) {
  return ({
    result: $(element.closest('.simple_form')).find('#' + element[0].id.replace('_select2', '')).attr('value') })
}

function init() {
  $("select[id$='_select2']").each(function (i, el) {
    let query_params = JSON.parse(el.dataset.queryParams)
    let label_entity_attribute = el.dataset.labelEntityAttribute || 'entity_type'
    let label_attribute = el.dataset.labelAttribute
    let label_entity = el.dataset.labelEntity
    let is_polymorphic = el.dataset.polymorphic
    $(el).select2({
      ajax: {
        url: query_params.url,
        dataType: 'json',
        delay: query_params.delay || 250,
        data: function (params) {
          var elements = {}
          elements[query_params.param] = params.term;
          elements['page'] = params.page;
          return (elements);
        },
        processResults: function (data, params) {
          params.page = params.page || 1;
          for (var i = 0; i < data.length ; i++)
            data[i].entity_type = data[i].entity_type || query_params.entity_type
          return {
            results: data,
            pagination: {
              more: (params.page * 30) < data.total_count
            }
          };
        },
        cache: true
      },
      allow_clear: true,
      placeholder: "Select a value",
      minimumInputLength: 2,
      dropdownAutoWidth: true,
      escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
      templateResult: function (data) {
        var label = (label_attribute ? data[label_attribute] : (data.login || data.email || data.first_name || data.last_name || data.slug));
        return `<span><b>${label_entity || data[label_entity_attribute]}</b> ${label}</span>`
      },
      templateSelection: function (data) {
        var label = (label_attribute ? data[label_attribute] : (data.login || data.email || data.first_name || data.last_name || data.slug));
        var entity = (label_entity || data[label_entity_attribute])
        var res = ""
        var fields

        if (label)
          if (entity)
            res = `<span><b>${entity}</b> ${label}</span>`
          else
            res = `<span>${label}</span>`

        if (is_polymorphic == 'true') {
          fields = getPolymorphicFieldsValues($(el))
          if (fields['id'] && fields['id'].length != 0 && fields['id'] && fields['id'].length != 0) {
            if (label)
              localStorage.setItem($(el)[0].id + '_' + fields['id'] + fields['type'], res);
            if (res == "")
              res = localStorage.getItem($(el)[0].id + '_' + fields['id'] + fields['type'])
          }
        }
        else {
          fields = getFieldValue($(el))
          if (fields['result'] && fields['result'].length != 0) {
            if (label)
              localStorage.setItem($(el)[0].id + '_' + fields['result'], res);
            if (res == "")
              res = localStorage.getItem($(el)[0].id + '_' + fields['result'])
          }
        }
        if (res)
          $('#select2-' + $(el)[0].id + '-container').find('.select2-selection__placeholder').removeClass('select2-selection__placeholder') // Ca ne fontionne pas dutout !
        else
          res = `<span>${data.text}</span>`
        return (res);
      }
    })
    if (is_polymorphic == 'true') {
        $(el).on('select2:select', updatePolymorphicFields)
        $(el).on('select2:unselect', updatePolymorphicFields)
    } else {
        $(el).on('select2:select', updateField)
        $(el).on('select2:unselect', updateField)
    }
  });
}
