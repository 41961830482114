export default {
  selectResourceChange,
  refresh,
  selectMailingChange
}

function selectMailingChange (selector) {
  let selected = selector.selectedOptions[0].value;
  let mailingSelectElement = undefined
  let next = selector

  for (;;) {
    let parentElement = next.parentElement
    if (!parentElement) { return }
    if (parentElement.classList.contains('mailing-select')) {
      mailingSelectElement = parentElement
      break
    }
    next = parentElement
  }

  [...$(mailingSelectElement).find('.mailingShowBtn')].forEach(e => {
    if (selected == "") {
      e.classList.add('disabled')
    } else {
      e.href = e.attributes.baseUrl.value.replace(":mailing_id", selected)
      e.classList.remove('disabled')
    }
  })
}

function refresh () {
  let mailableTypeSelector = $("select[name='mailing[mailable_type]']")
  if (mailableTypeSelector.length != 0) {
    [...mailableTypeSelector].forEach(e => {
      selectResourceChange(e)
    })
  }
}

function getDataFromSelect (selector) {
  if (!selector.attributes.parsedData) {
    selector.attributes.parsedData = JSON.parse(selector.attributes.data.value)
  }
  return selector.attributes.parsedData
}

function selectResourceChange (selector) {
  let selected = selector.selectedOptions[0].value
  let data = getDataFromSelect(selector)
  let target = $(selector.attributes.target.value)
  let selectedData = data[selected];

  if (!selectedData) { selectedData = [] }
  [...target].forEach(e => {
    e.innerHTML = selectedData.map(e => { return `<br>%{${e}}  ` }).join('')
  })
}
