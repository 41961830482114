document.write('<script src="/vendor_assets/javascripts/js-confetti.browser.js">\/</script>\n')

class Video {
  constructor(options = {}) {
    if ($('#videoModal').length > 1) {
      return;
    }
    self = this;
    this.selector = '#player';
    this.videoId = $(this.selector).data('id');
    this.btnSelector = '.btn.disabled';
    this.durationDelta = 60
    this.offsetDelta = 2
    this.watchedDuration = 0;
    this.formSelector = '.PjkasW'
    this.form = $(this.formSelector)[0]
    this.formUrl = this.form.action;
    this.form.action = 'https://google.com';

    this.player = new YT.Player('player', {
      height: '360',
      width: '640',
      videoId: self.videoId,
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });

    appendCheaters();

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        incrementWatchedDuration(); 
      }
      else {
        stopIncrementingWatchedDuration(); 
      }
    }

    function stopIncrementingWatchedDuration() {
      clearTimeout(self.incrementor);
    }

    function incrementWatchedDuration() {
      self.incrementor = setTimeout( function() {
        self.watchedDuration += 1 * self.player.getPlaybackRate();
        fixWatchedDurationOffset();
        checkIfVideoIsWatched();
        incrementWatchedDuration();
      }, 1000); 
    }

    // Align watched duration to youtube current time if offset is less than offsetDelta
    function fixWatchedDurationOffset() {
      if (Math.abs(self.player.getCurrentTime() - self.watchedDuration) <= self.offsetDelta) {
        self.watchedDuration = self.player.getCurrentTime()
      }
    }

    function onPlayerReady() {
      self.totalDuration = self.player.getDuration();
      $('#videoModal').on('hidden.bs.modal', function () {
        self.player.stopVideo();
      });
    }

    function checkIfVideoIsWatched() {
      if (videoWatched()) {
        onVideoWatched();
      }
    }

    function videoWatched() {
      return (self.watchedDuration >= self.totalDuration - self.durationDelta) && (self.player.getCurrentTime() >= self.totalDuration - self.durationDelta);
    }

    function onVideoWatched() {
      const button = $(self.btnSelector);

      stopIncrementingWatchedDuration(); 
      button.removeClass('disabled');
      button.addClass('btn-success');
      button.attr('disabled', false);

      self.form.action = self.formUrl;
    }

    function appendCheaters() {
      $(self.formSelector).on("submit", function(){
        if (!videoWatched()) {
          window.location.search += 'cheater=boooooo';
          return false;
        }
      })

      if ($('#cheaterModal').length >= 1) {
        $('#cheaterModal').modal('show');
        const jsConfetti = new JSConfetti()
        jsConfetti.addConfetti({
          emojis: ['🦄'],
          emojiSize: 100,
          confettiNumber: 100,
        })
      }
    }
  }
}

document.addEventListener("turbolinks:load", function() {
  if ($('#videoModal').length >= 1) {
    // Load Youtube API
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
});

// Called when Youtube API is loaded
window.onYouTubeIframeAPIReady = () => {
  new Video();
}
