$( document ).on('turbolinks:load', function() {
  $('.custom-file-label').each(function correctForField() {
    $(this).attr('for', ($(this).attr('for').replace(/\[/g, '_').replace(/\]/g, '')))
    if ($(this).parent().find("#campus_logo").length) {
      var origin_title = $(this).attr("title");
      var new_title = `<div style="width:150px;height:150px;"> ${origin_title}</div>`;
      $(this).attr("title", new_title);
    }
  });
});
