import 'AddCalEvent'

export default function initCalendarDropdown() {

	var title = document.getElementById('event-title').innerHTML
	var desc = document.getElementById('event-desc').innerHTML
	var loc = document.getElementById('event-location').innerHTML
	var url = document.getElementById('event-url').innerHTML
	var start = document.getElementById('event-start').innerHTML
	var end = document.getElementById('event-end').innerHTML
	var zone = document.getElementById('event-zone').innerHTML
	var ical = document.getElementById('event-ical').innerHTML


	$(".add-cal-event").addcalevent({
	'data': {
	  "title": title,
	  "desc": desc,
	  "location": loc,
	  "url": url,
	  "time":{
	  "start": start,
	  "end": end,
	  "zone": zone,
	},
	},
		'ics': ical,
	});
}	
