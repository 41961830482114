/**
 * Replaces the text of button when the parent form successfully performs an ajax request
 * @example
 * = f.submit, 'save', data: {'disable-with': 'saving...', 'replace-with': 'saved'}
 */

export function replaceWith() {
  $('form').each(function() {
    $(this).submit(function() {
      var submitButton = $(this).find("input[type=submit]")
      submitButton.attr("value", "...");
      submitButton.attr("disabled", "disabled");
    });
  });
}
