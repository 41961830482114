class Pipeline {
  constructor(options = {}) {
    self = this;
    self.emailsList = [];
    self.baseUrl = options['baseUrl'] || $('#upload-emails-container').attr('data-url');
    self.submitBtn = $("form#uploadEmails input[type=submit]");
    $("form#uploadEmails").on('click', "input[type=submit]" , function(e){;
      let emailsList = $("form#uploadEmails textarea[name='user[emails]']").val().split("\n").filter(Boolean);
      self.setDataAsLoading()
      self.getPipelineData(emailsList);
    });
  }

  setDataAsLoading() {
    let data_confirm_val = self.submitBtn.data("confirm");
    let $data_confirm_elem = $("<p>" + data_confirm_val + "</p>");

    $data_confirm_elem.find("b#count_existing_emails").text("...")
    $data_confirm_elem.find("b#count_non_existing_emails").text("...")

    self.submitBtn.getConfirmModal().find('.modal-body p').html($data_confirm_elem.html());
    self.submitBtn.confirmModal();
  }

  getPipelineData(emailsList) {
    let url = self.baseUrl + '.json?pipeline[emails]=' + emailsList;
    let data_confirm_val = self.submitBtn.data("confirm");
    let $data_confirm_elem = $("<p>" + data_confirm_val + "</p>");
    $.get({
      url: url,
      success: function(response) {
        $data_confirm_elem.find("b#count_existing_emails").text(response.count_existing_emails);
        $data_confirm_elem.find("b#count_non_existing_emails").text(response.count_non_existing_emails);
        if (response.count_non_existing == 0) {
          let data_confirm_html = $data_confirm_elem.html();
          $data_confirm_elem.html(data_confirm_html.substring(0, data_confirm_html.lastIndexOf("<br>")));
        }
        self.submitBtn.getConfirmModal().find('.modal-body p').html($data_confirm_elem.html());
        self.submitBtn.confirmModal();
      },
      error: function(response) {
        let error_txt = '<br/><br/><p class="text-danger">There was an error retrieving the informations of the pipeline applicants !</p>';
        self.submitBtn.getConfirmModal().find('.modal-body p').html(data_confirm_val + error_txt);
        self.submitBtn.confirmModal();
      }
    });
  }
}

document.addEventListener("turbolinks:load", function() {
  if ($('#upload-emails-container').length >= 1) {
    let pipeline = new Pipeline();
  }
});
