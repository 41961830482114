function bindSortable() {
  $('.sortable').sortable({
   handle: '.sortable-handle',
   onSort: function(evt) {
    updatePositions()
   }
  });
  $('.sortable').on('cocoon:after-insert', function(e, insertedItem) {
    updatePositions()
  })
  $('.sortable').on('cocoon:after-remove', function(e, insertedItem) {
    updatePositions()
  })
}

function updatePositions() {
  _.each($('.sortable').children('.sortable-item:visible'), function(item, index) {
    $(item).find('.sortable-position').html(index + 1);
    $(item).find('input.sortable-position').val(index + 1);
  });
}

document.addEventListener("turbolinks:load", function() {
  bindSortable()
})
