export function bindRemoveFileFromInput() {
  Array.from($('button[data-remove-input]')).forEach(function(button) {
    $(button).on('click', function(e) {
      var input = $(`[name="${this.dataset.removeInput}"]`).first();
      input.next('label').html(input.val());
      if (input.next('label').hasClass('removeable-file')) {
        input.parents('.simple_form').append(`
          <input type="hidden" name="${this.dataset.addInput}" value="true">        
        `);
      }
      input = input.replaceWith(input.val('').clone(true));
      var self = this
      $(input).on('change',function(){
        if ($(this).val()) {
          $(self.dataset.addInput).remove();
        }
      })
    })
  })
}
