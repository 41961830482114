export default { 
  init
}

function init () {
  // $(document).ready(initSearch)
  // $(document).on('load', initSearch)
}

// function 
