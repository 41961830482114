window.addEventListener('DOMContentLoaded', (e) => {
    const btn = document.getElementById('edit_user_btn');
    if (btn)
        btn.addEventListener('click', getFormData);
})



function getFormData() {
    const form = document.getElementById("edit_user");
    if (form) {
        const user = {
            first_name: document.getElementById("user_first_name").value,
            last_name: document.getElementById("user_last_name").value,
            gender: document.getElementById("user_gender").value,
            dob: document.getElementById("user_birth_date").value,
            country: getCountry("user_country")
        };
        
        const targetNode = document.documentElement || document.body;

        const config = {
            attributes: true,
            subtree: true
        }

        const callback = (mutations) => {
            mutations.map(m => {
                if (m.target.classList[0] === 'modal-open')
                    getModal(user, observer)
            })
        }
        
        const observer = new MutationObserver(callback)
        
        observer.observe(targetNode, config)
    }   
}

function getModal(user, observer) {
    const modal_body = document.getElementsByClassName('modal-body')[0]
    const cancelBtn = document.getElementsByClassName('btn cancel btn-default')[0];
    cancelBtn.addEventListener('click', resetModalData(observer));
    addUserDataToModal(modal_body, user.first_name, document.querySelector("#edit_user > div.container > div.form-group.string.optional.user_first_name > label").innerText);
    addUserDataToModal(modal_body, user.last_name, document.querySelector("#edit_user > div.container > div.form-group.string.optional.user_last_name > label").innerText);
    addUserDataToModal(modal_body, user.gender, document.querySelector("#edit_user > div.container > div.form-group.select.optional.user_gender > label").innerText);
    addUserDataToModal(modal_body, user.dob, document.querySelector("#edit_user > div.container > div.form-group.date.optional.user_birth_date > label").innerText);
    addUserDataToModal(modal_body, user.country, document.querySelector("#edit_user > div.container > div.form-group.country.optional.user_country > label").innerText);
}

function addUserDataToModal(modal_body, val, label) {
    if (val) 
        showUserData(modal_body, label + ": " + val)
    else 
        showUserData(modal_body, label, 'text-danger font-weight-bold');
}

function showUserData(modal, text, css) {
   
    const p = document.createElement('p');
    p.className = 'collection-item ' + css;
    p.append(text);
    modal.append(p);
}

function getCountry(id){
    const el = document.getElementById(id);
    return el.options[el.selectedIndex].text;
}

function resetModalData(observer) {
    observer.disconnect()
    document.querySelectorAll('.collection-item').forEach(el => el.remove());
}
