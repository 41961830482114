class filterTable {
  constructor() {
    let self = this;
    self.input = document.getElementById("filter-input");
    self.table = document.getElementById("filter-table");
    self.tr = self.table.getElementsByTagName("tr");
    self.bindSearch();
  }

  bindSearch() {
    let self = this;
    $(self.input).keyup( function() {
      var i = 0;
      for (i = 0; i < self.tr.length; i++) {
        if (self.matchesValue(self.tr[i])) {
          self.tr[i].style.display = "";
        } else {
          self.tr[i].style.display = "none";
        }
      }
    });
  }

  matchesValue(tr) {
    let self = this;
    let rowValue = _.map(tr.getElementsByTagName("td"), function(td) {
      return _.includes(td.classList, 'filterable') ? td.innerHTML.toUpperCase() : ''
    });
    rowValue =  _.join(rowValue, ' ')
    let inputValue = self.input.value.toUpperCase();
    if (rowValue.indexOf(inputValue) > -1) {
      return true
    } else {
      return false
    }
  }
}


document.addEventListener("turbolinks:load", function() {
  if (document.getElementById("filter-input")) {
    new filterTable();
  }
});
